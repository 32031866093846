.nds-details {
  position: relative;
  display: inline-block;

  .chevron {
    &.open {
      transform: rotate(-180deg);
    }
    transition: transform 0.2s;
  }

  & .nds-details-container {
    display: flex;
    flex-direction: column;
  }
  &:not(.wide) .nds-details-container {
    padding-left: 0px;
    @media (min-width: $desktop-small) {
      padding-left: 20px;
    }
  }

  & .nds-details-summary {
    display: inline-flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    > .nds-button--menu {
      margin-right: 2px;
    }
  }

  & .nds-details-container .nds-button--menu {
    font-weight: normal;
    font-size: 16px !important;
    display: block;
    &:hover {
      text-decoration: underline;
    }
    @media (min-width: $desktop-small) {
      margin: 0;
    }
  }

  @media (min-width: $desktop-small) {
    & > .nds-modal[data-open="true"] > .nds-modal-overlay {
      display: block;
      opacity: 0;
    }
    & > .nds-modal > .nds-modal-container {
      position: absolute;
      width: min-content;
      transform: translate(-50%, 60px);
    }
    & > .nds-modal.medium > .nds-modal-container {
      position: absolute;
      width: 372px;
      transform: translate(-72%, 60px);

      .nds-details-container {
        margin: 0;
        padding: 0px var(--space-m) 0px var(--space-l);
        border-radius: 0px 0px var(--space-xxs) var(--space-xxs);
        .tools .nds-button--menu {
          margin: 0;
        }
      }
    }

    .nds-modal.wide > .nds-modal-container {
      width: 100vw;
      transform: none;
      position: fixed;
      top: 72px;
      left: 0;

      .nds-details-container {
        & > div {
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          & > div {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            &:first-of-type {
              padding-left: 0;
            }
          }
          & > div:not(:last-child) {
            border-right: solid 1px rgba(var(--nds-lightest-grey), 1);
          }
        }
      }
    }
    .nds-modal[data-open="true"] > .nds-modal-container {
      border-top: 1px solid RGB(var(--nds-lightest-grey));
      box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.1);
    }
    .nds-modal[data-open="true"].medium > .nds-modal-container {
      border-top: 1px solid RGB(var(--nds-lightest-grey));
      box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.1);
    }
    & .nds-details-container {
      margin: 0;
      padding: 20px 0;
      border-radius: 0px 0px 4px 4px;
      .tools .nds-button--menu {
        margin: 0;
      }
    }
  }
}

.navbar-nds-btn {
  &:hover {
    .nds-details-summary {
      .nds-button-label {
        color: var(--theme-primary) !important;
      }
    }
  }
}

/**
* constraints for account columns in a `Details`
*/
[class*="navbar-accountGroupColumn"] {
  max-width: 246px; // mobile default
}
@media (min-width: $desktop-small) {
  // generates variant classNames for column constraints
  @each $cols in (1, 2, 3, 4, 5) {
    .navbar-accountGroupColumn--#{$cols} {
      max-width: #{90 / $cols}vw !important; // reserve 10vw for X spacing
    }
  }
}
